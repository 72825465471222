import Alert from "sweetalert2";

import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import { USER_PLAN, USER_PLAN_CREATE, USER_PLAN_DELETE, USER_PLAN_EDIT, USER_PLAN_PAUSE } from "../../../actions/userPlan"
import {ROOT_URL} from '../../../config/'

export const userPlanMiddleware = ({dispatch}) => next => (action) => {
    next(action)
    switch(action.type){
        case USER_PLAN_CREATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/userPlan`,
                method:'post',
                feature:USER_PLAN
            }))
            break;

        case USER_PLAN_EDIT:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/userPlan`,
                method:'put',
                feature:USER_PLAN
            }))
            break;

        case USER_PLAN_PAUSE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/pausePlan`,
                method:'put',
                feature:USER_PLAN
            }))
            break;

        case USER_PLAN_DELETE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/userPlan`,
                method:'delete',
                feature:USER_PLAN
            }))
            break;

        case `${USER_PLAN} ${API_SUCCESS}`:
            Alert.fire({
                position: 'top-end',
                icon: 'success',
                title: action.message,
                showConfirmButton: false,
                timer: 1500
                })  
                window.location.href = '/allUsers'
        break;

        case `${USER_PLAN} ${API_ERROR}`:
            Alert.fire({
                position: 'top-end',
                icon: 'error',
                title: action.message,
                showConfirmButton: false,
                timer: 1500
                })
            break;

            default: break;
    }
}
