import React,{useState,useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Alert from "sweetalert2";
import { getProvider } from '../../../actions/provider';
import { getOfferingType } from '../../../actions/offering';
import { getAllUsers } from '../../../actions/users';
import { offeringTypeSelector } from '../../../selector/offering';
import { providerSelector } from '../../../selector/provider';
import { usersSelector } from '../../../selector/users';
import './searchStyles.css'
import { createEvent, editEvent } from '../../../actions/event';
import moment from 'moment';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import UserTable from './UserTable'
import CreatableSelect from "react-select/creatable";

const  EventForm = ({
    name,type,typeId,by,byId,st,time,dur,participants,eventId,joinUrl,eventDetails, role
}) => {
    const [eventName,setEventName] = useState(name || '')
    const [validateEventName,setValidateEventName] = useState(true)
    const [eventType,setEventType] = useState(type || '')
    const [eventTypeId,setEventTypeId] = useState(typeId || '')
    const [validateEventType,setValidateEventType] = useState(true)
    const [ledBy,setLedBy] = useState(by || '')
    const [validateLedBy,setValidateLedBy] = useState(true)
    const [ledById,setLedById] = useState(byId || '')
    const [startDate,setStartDate] = useState(st || moment(new Date()).format("YYYY-MM-DD"))
    const [startTime,setStartTime] = useState( time || '12:00')
    const [duration,setDuration] = useState(dur || '')
    const [eventLink,setEventLink] = useState(joinUrl || '')
    const [validateEventLink,setValidateEventLink] = useState(true)
    const [validateDuration,setValidateDuration] = useState(true)
    const [repeatMonth,setRepeatMonth] = useState(false)
    const [repeatWeek,setRepeatWeek] = useState(false)
    const [repeatDays,setRepeatDays] = useState([])
    const [selectedUsers,setSelectedUsers] = useState(participants || [])
    const [selectedOption, setSelectedOption] = useState([])
    const [roleId, setRoleId] = useState(role || '')


    const start = `${startDate} ${startTime}`
    
    const dispatch = useDispatch()
    const history = useHistory()
    
    useEffect(() => {
        dispatch(getAllUsers({}))
        dispatch(getOfferingType())
        dispatch(getProvider())

        setEventName(name || '');
        setEventType(type || '');
        setLedBy(by || '');
        setLedById(byId || '');
        setStartDate(st || moment(new Date()).format("YYYY-MM-DD"))
        setStartTime(time || '12:00');
        setDuration(dur || '')
        setEventLink(joinUrl || '')
        setRoleId(role || '')

        const script = document.createElement("script");
        script.src = "/assets/js/advancedForm.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, [dispatch, name]);

    const offeringType = useSelector(state=>offeringTypeSelector(state))
    const provider = useSelector(state=>providerSelector(state))
    
    const map = new Map();
    map.set("OFRT001", "ROLE0004");
    map.set("OFRT002", "ROLE0004");
    map.set("OFRT003", "ROLE0004");
    map.set("OFRT004", "ROLE0004");
    map.set("OFRT005", "ROLE0004");
    map.set("OFRT008", "ROLE0004");
    map.set("OFRT011", "ROLE0004");
    map.set("OFRT006", "ROLE0005");
    map.set("OFRT007", "ROLE0006");
    map.set("OFRT010", "ROLE0003");

    const allUsers = useSelector((state)=>usersSelector(state))
    const options = allUsers.users?allUsers.users.map((user) => ({ label: `${user.firstName} ${user.lastName} (${user.callingNumber})`, value: user.userId })):[]//.map((user)=>({name:`${user.firstName} ${user.lastName} (${user.callingNumber})`,value:user.userId})):[]

    const putDays = (day) => {
        const daySet = new Set(repeatDays)
        if(daySet.has(day)){
            daySet.delete(day)
            setRepeatDays(Array.from(daySet))
        }else{
            daySet.add(day)
            setRepeatDays(Array.from(daySet))
        }
    }
 
    const renderRepeat = () => {
        return(
            <div className="card">
                <h6 className="card-header">Repeat this event</h6>
                <div className="card-body">
                    <div>
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="customCheck1" onClick={()=>{putDays(0)}}/>
                            <label className="form-check-label" for="customCheck1">Sunday</label>
                        </div>
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="customCheck2" onClick={()=>{putDays(1)}}/>
                            <label className="form-check-label" for="customCheck2">Monday</label>
                        </div>
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="customCheck3" onClick={()=>{putDays(2)}}/>
                            <label className="form-check-label" for="customCheck3">Tuesday</label>
                        </div>
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="customCheck4" onClick={()=>{putDays(3)}}/>
                            <label className="form-check-label" for="customCheck4">Wednesday</label>
                        </div>
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="customCheck5" onClick={()=>{putDays(4)}}/>
                            <label className="form-check-label" for="customCheck5">Thursday</label>
                        </div>
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="customCheck6" onClick={()=>{putDays(5)}}/>
                            <label className="form-check-label" for="customCheck6">Friday</label>
                        </div>
                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="customChec7" onClick={()=>{putDays(6)}}/>
                            <label className="form-check-label" for="customCheck7">Saturday</label>
                        </div>
                    </div> 
                    <hr/>
                    <div 
                        className="form-check form-radio-success mb-2"
                        onClick={()=>{setRepeatMonth(true);setRepeatWeek(false)}}    
                    >
                        {repeatMonth?<input type="radio" id="monthRadio" name="monthRadio" className="form-check-input" checked/>:<input type="radio" id="monthRadio" name="monthRadio" className="form-check-input"/>}
                        <label className="form-check-label" htmlFor="monthRadio">Repeat this for a month</label>
                    </div>
                    <div className="form-check form-radio-success mb-2"
                        onClick={()=>{setRepeatWeek(true);setRepeatMonth(false)}}
                    >
                        {repeatWeek?<input type="radio" id="weekRadio" name="weekRadio" className="form-check-input" checked/>:<input type="radio" id="weekRadio" name="weekRadio" className="form-check-input"/>}
                        <label className="form-check-label" htmlFor="monthRadio">Repeat this for a week</label>
                    </div>
                </div>
                <button className="btn btn-danger" onClick={()=>{setRepeatWeek(false); setRepeatMonth(false)}}>Clear</button>
            </div>
            
        )
    }

    const handleAddParticipant = () => {
        const user = allUsers.users.find(element=>element.userId===selectedOption.value)
        setSelectedUsers([...selectedUsers,user])       
    }

    const handleChange = (e) => { 
        if(e?.__isNew__){
            const contact = e.value
            dispatch(getAllUsers({contact}))
        }else{
            setSelectedOption(e)
        }
    }

    const handleDeleteParticipant = (target) =>{
        setSelectedUsers(selectedUsers.filter(user=>user.userId!==target))
    }


    const handleLedBy = (event) =>{
        setLedBy(event.target.value)
        setLedById((event.target.value.split(' ').slice(-1)[0]))
    } 

    const handleCreateEvent = () =>{
        if(eventName===''){
            setValidateEventName(false)
        }
        if(eventType==='Select Event Type'){
            setValidateEventType(false)
        }
        if(ledBy==='Select Event Lead'){
            setValidateLedBy(false)
        }
        if(eventLink === ''){
            setValidateEventLink(false)
        }
        if(duration==='Select Event Duration In Minutes'){
            setValidateDuration(false)
        }
        else{
            Alert.fire({
                title:'Create this event ?',
                showDenyButton:true,
                showConfirmButton:true,
                confirmButtonText:'Go Ahead !',
                denyButtonText:'Nope Wait',
            }).then(({value})=>{
                if(value){
                    if(repeatMonth){
                        dispatch(createEvent({
                            eventName,
                            eventType,
                            ledById,
                            start,
                            duration,
                            eventLink,
                            repeat:'month',
                            repeatDays,
                            participants: selectedUsers.map((item)=>item.userId)
                        }))
                    }else if (repeatWeek){
                        dispatch(createEvent({
                            eventName,
                            eventType,
                            ledById,
                            start,
                            duration,
                            eventLink,
                            repeat:'week',
                            repeatDays,
                            participants: selectedUsers.map((item)=>item.userId)}))
                    }else{
                        dispatch(createEvent({
                            eventName,
                            eventType,
                            ledById,
                            start,
                            duration,
                            eventLink,
                            repeat:false,
                            repeatDays,
                            participants: selectedUsers.map((item)=>item.userId)
                        }))
                    }  
                    history.push('/event')
                }
            })
        }  
    }
    const handleEditEvent = () =>{
        Alert.fire({
            title:'Edit this event ?',
            showDenyButton:true,
            showConfirmButton:true,
            confirmButtonText:'Go Ahead !',
            denyButtonText:'Nope Wait',
        }).then(({value})=>{
            if(value){
                dispatch(editEvent({
                    eventId,
                    eventName,
                    eventType,
                    ledById,
                    start,
                    duration,
                    eventLink,
                    participants:selectedUsers.map((item)=>item.userId)
                }))
                history.push('/event')
            }
        })
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-9">
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-name"
                                            className="col-2 col-form-label"
                                        >
                                            Event Name
                                        </label>
                                        <div className="col-9">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={eventName}
                                                onChange={(e) => {
                                                    setEventName(e.target.value);
                                                }}
                                                onBlur={(e)=>{
                                                    if(e.target.value === ''){
                                                        setValidateEventName(false)
                                                    }else{
                                                        setValidateEventName(true)
                                                    }
                                                }}
                                                id="event-name"
                                                placeholder="Event Name"
                                                autoComplete="off"
                                            />
                                            {validateEventName === false ?(
                                                <div className="text-danger">
                                                    Please give event a name.
                                                </div>
                                            ):null}
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="event-type"
                                            className="col-2 col-form-label"
                                        >
                                            
                                            Event Type
                                        </label>
                                        <div className="col-9">
                                            <select
                                                className="form-select"
                                                id="event-type"
                                                value={eventType}
                                                onChange={e=>{
                                                        const selectedIndex = e.target.options.selectedIndex;
                                                        setEventType(e.target.value)
                                                        const offeringTypeId = e.target.options[selectedIndex].getAttribute('a-key')
                                                        setRoleId(map.get(offeringTypeId));
                                                        setEventTypeId(offeringTypeId);
                                                    }
                                                }
                                                placeholder="Select Event Type"
                                                onBlur={(e)=>{
                                                    if(e.target.value === 'Select Event Type'){
                                                        setValidateEventType(false)
                                                    }else{
                                                        setValidateEventType(true)
                                                    }
                                                }}
                                            >
                                                <option value="" disabled selected>Select Event Type</option>
                                                {offeringType?offeringType.map((offer)=>{
                                                    return(
                                                        <option a-key={offer.offeringTypeId}>{offer.offeringTypeName}</option>
                                                    )
                                                }):null}
                                            </select>
                                            {
                                                validateEventType === false ? (
                                                <div className="text-danger">
                                                    Please select event type
                                                </div>
                                                ) : null
                                            }
                                        </div>
                                        
                                    </div>
                                    <div className="row mb-3">
                                        <label
                                            htmlFor="led-by"
                                            className="col-2 col-form-label"
                                        >
                                            
                                            Led By
                                        </label>
                                        <div className="col-9">
                                            <select
                                                className="form-select disabledbutton"
                                                id="led-by"
                                                value={ledBy}
                                                disabled={eventType === '' ? true : false}
                                                onChange={e=>{handleLedBy(e)}}
                                                onBlur={(e)=>{
                                                    if(e.target.value === 'Select Event Lead'){
                                                        setValidateLedBy(false)
                                                    }else{
                                                        setValidateLedBy(true)
                                                    }
                                                }}
                                            >
                                                <option value="" disabled selected>Select Event Lead</option>
                                                {provider?provider.map((item)=>{
                                                    if(item.roleId === roleId)
                                                    {
                                                        return (<option key={item.userId}>{item.name} - {item.roleName} - {item.userId}</option>)
                                                    }
                                                    else{
                                                        return "";
                                                    }
                                                }):null}
                                            </select>
                                            {
                                                validateLedBy === false ? (
                                                <div className="text-danger">
                                                    Please select event lead
                                                </div>
                                                ) : null
                                            }
                                        </div>
                                        
                                    </div>
                                    <div className="row mb-3">
                                            <label
                                                htmlFor="start-time"
                                                className="col-2 col-form-label"
                                            >
                                                Start Time
                                            </label>
                                            <div className="col-9">
                                                <div className="row">
                                                    <div className="col-8">
                                                        <input 
                                                            className="form-control" 
                                                            id="date" 
                                                            type="date" 
                                                            name="date"
                                                            min={moment(new Date()).format("YYYY-MM-DD")}
                                                            value={startDate}
                                                            onChange={(e)=>{setStartDate(e.target.value)}}

                                                            />
                                                    </div>
                                                    <div className="col-4">
                                                        <input 
                                                            className="form-control" 
                                                            id="example-time" 
                                                            type="time" 
                                                            name="time"
                                                            value={startTime}
                                                            onChange={(e)=>{setStartTime(e.target.value)}}
                                                            
                                                            />   
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                    <div className="row mb-3">
                                            <label
                                                htmlFor="duration"
                                                className="col-2 col-form-label"
                                            >
                                                Duration
                                            </label>
                                            <div className="col-9">
                                                <select
                                                    className="form-select"
                                                    id="led-by"
                                                    value={duration}
                                                    onChange={e=>setDuration(e.target.value)}
                                                    onBlur={(e)=>{
                                                        if(e.target.value === 'Select Event Duration in Minutes'){
                                                            setValidateDuration(false)
                                                        }else{
                                                            setValidateDuration(true)
                                                        }
                                                    }}
                                                >
                                                    <option value="" disabled selected>Select Event Duration in Minutes</option>
                                                    <option >15</option>
                                                    <option >30</option>
                                                    <option >45</option>
                                                    <option >60</option>
                                                </select>
                                                {
                                                    validateDuration === false ? (
                                                        <div className="text-danger">
                                                            Please select event duration in minutes
                                                        </div>
                                                    ) : null
                                                }
                                            </div>
                                    </div>
                                    {!['OFRT006', 'OFRT007', 'OFRT010'].includes(eventTypeId) && (
                                        <div className="row mb-3">
                                            <label
                                                htmlFor="event-link"
                                                className="col-2 col-form-label"
                                            >
                                                Event Link
                                            </label>
                                            <div className="col-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={eventLink}
                                                    onChange={(e) => {
                                                        setEventLink(e.target.value);
                                                    }}
                                                    onBlur={(e)=>{
                                                        if(e.target.value === '' && !['OFRT006', 'OFRT007', 'OFRT010'].includes(eventTypeId)){
                                                            setValidateEventLink(false)
                                                        }else{
                                                            setValidateEventLink(true)
                                                        }
                                                    }}
                                                    id="event-link"
                                                    placeholder="Event Link"
                                                    autoComplete="off"
                                                />
                                                {validateEventLink === false ?(
                                                    <div className="text-danger">
                                                        Please enter event link.
                                                    </div>
                                                ):null}
                                            </div>
                                        </div>
                                    )}
                                    <div className="row">
                                        <div className="justify-content-center d-flex" style={{marginTop:'1.75rem'}}>
                                            <button 
                                                type="submit" 
                                                className="btn btn-success"
                                                onClick={()=>{eventId?handleEditEvent():handleCreateEvent()}}
                                            >                   
                                                {eventId?'Edit Event':'Create Event'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">                                        
                                    {eventId?null:renderRepeat()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                            <div className="col-lg-9">
                                    <div className="row">
                                        <label
                                            htmlFor="zoom-link"
                                            className="col-2 col-form-label"
                                        >
                                            Add Participant
                                        </label>
                                        <div className="col-9">
                                            <CreatableSelect
                                                options={options}
                                                isSearchable
                                                placeholder="Select Participants"
                                                onChange={handleChange}
                                                value={selectedOption}
                                                isClearable
                                                formatCreateLabel={(inputValue) => "Search "+ inputValue}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <button 
                                        type="submit" 
                                        className="btn btn-info w-100"
                                        onClick={handleAddParticipant}
                                    >
                                            Add Participant
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
            <UserTable
                users={selectedUsers}
                handleDeleteParticipant={handleDeleteParticipant}
                showActions={true}
            />

              
        </>
    )
}

export default EventForm
