import axios from 'axios'
import {apiSuccess,apiError,API_REQUEST} from '../../../actions/api'
import { forceLogout, LOGIN, LOGOUT, PASSWORD, setAuthentication} from '../../../actions/login';
import { ACQ_CHANNEL } from '../../../actions/acqChannel';
import { PRIMARY_CONCERN } from '../../../actions/primaryConcern';
import { USER, USER_BOOKINGS, USER_MEAL_TEMPLATE, USER_OFFERING, USER_BOOKINGS_PERIOD_LOGS, USER_BOOKINGS_WEBINAR_LOGS, USER_BOOKINGS_SYMPTOM_LOGS, USER_BOOKINGS_CONSULT_LOGS, USER_LAB_REPORT, USER_EXPERT_REPORT } from '../../../actions/user';
import { TRIAL_USERS, ADMIN_LOGIN, USERS, HEALTH_COACH } from '../../../actions/users';
import { USER_PRIMARY_CONCERN } from '../../../actions/userPrimaryConcern';
import { SYMPTOM } from '../../../actions/symptom';
import { USER_SYMPTOM } from '../../../actions/userSymptom';
import { OFFERING, OFFERING_STATUS,OFFERING_TYPE, OFFERING_UNIT } from '../../../actions/offering';
import {PROVIDER} from '../../../actions/provider'
import { EVENT, EVENTS, EVENTS_CALENDAR, FILTERED_CALENDAR_EVENTS, RESCHEDULE } from '../../../actions/event';
import { PLAN, PLANS, PLAN_GET } from '../../../actions/plan';
import { ALL_BOOKINGS, EVENT_INSIGHTS, MY_BOOKINGS, USER_INSIGHTS, PAYMENT_INSIGHTS } from '../../../actions/insights';
import { EXOTEL_LOGS } from '../../../actions/exotel';
import { RAZORPAY_LOGS } from '../../../actions/razorpay';
import { USER_PLAN } from '../../../actions/userPlan';
import { COUPONS, COUPON, VOUCHERS, VOUCHER } from '../../../actions/promoCodes';
import { CRON, CRONS } from '../../../actions/crons';
import { PROFILE_IMAGE_LOC, PROFILE_PRESIGNED_URL } from '../../../actions/profilePhotoUpload';
import { UNMARKED_EVENT } from '../../../actions/navbarStats/unmarkedTrials';
import { LAB_TESTS, LAB_REPORT_PRESIGNED_URL, LAB_REPORT_URL, LAB_TESTS_CONFIG, LAB_TEST_THUMBNAIL_PRESIGNED_URL, LAB_TEST_SKU, LAB_TEST_SKUS } from '../../../actions/labTests';
import { WEBINAR, WEBINARS, WEBINAR_USERS} from '../../../actions/webinar';
import { CHALLENGE, CHALLENGE_USER, CHALLENGES, CHALLENGE_LANDING_PRESIGNED_URL, CHALLENGE_THUMBNAIL_PRESIGNED_URL, CHALLENGE_HERO_PRESIGNED_URL, LEADERBOARD, CHALLENGE_ENGAGEMENT } from '../../../actions/challenges';
import { TASK, TASKS, TASK_THUMBNAIL_PRESIGNED_URL } from '../../../actions/tasks';
import {MEALS, MEAL_TEMPLATE_PRESIGNED_URL, MEAL_TEMPLATE} from "../../../actions/meals"
import { PAYMENT } from '../../../actions/payments';
import {TOPICS, CONTENT_ITEMS, FEATURE_CREATE_TOPICS, 
        FEATURE_UPDATE_TOPICS, FEATURE_DELTE_TOPICS,
        FEATURE_CREATE_CONTENT, FEATURE_DELTE_CONTENT, FEATURE_UPDATE_CONTENT
    } from "../../../actions/content"
import { TOPIC_CONTENT_MAP, TOPIC_CONTENT_ADD, TOPIC_CONTENT_REMOVE } from "../../../actions/topicsContentMap";
import { PAYMENTS_PLAN, PAYMENTS_PLAN_DETAILS } from '../../../actions/paymentsPlan';
import { EXPERTS, EXPERT, SPEC, EXPERT_STATUS } from '../../../actions/expert';
import { SKU, SKUS } from '../../../actions/SKU';
import { CONTENT_PRESIGNED_URL } from '../../../actions/contentImageUpload';
import {  COMMENT } from "../../../actions/comments"
import { ORDER, ORDERS } from '../../../actions/orders';
import { DOCTORS, DOCTOR_VERIFIED, DOCTOR_PATIENTS, DOCTOR_ONBOARDING, DOCTOR_PROFILE_PRESIGNED_URL, DOCTOR, PATIENT_TO_DOCTOR } from "../../../actions/doctorFacingTech"

export const apiMiddleware = ({dispatch}) => next => async(action) =>{
    next(action);
    if(action.type.includes(API_REQUEST)){
        let resp;
        try{
            switch(action.meta.feature){
                case LOGIN:
                case LOGOUT:
                case ACQ_CHANNEL:
                case PRIMARY_CONCERN:
                case USER:
                case USERS:
                case USER_PRIMARY_CONCERN:
                case SYMPTOM:
                case USER_SYMPTOM:
                case OFFERING:
                case OFFERING_TYPE:
                case OFFERING_UNIT:
                case PROVIDER:
                case EVENT:
                case EVENTS:
                case RESCHEDULE:
                case PLAN:
                case PLANS:
                case EVENT_INSIGHTS:
                case USER_INSIGHTS:
                case OFFERING_STATUS:
                case PLAN_GET:
                case EXOTEL_LOGS:
                case RAZORPAY_LOGS:
                case USER_PLAN:
                case COUPONS:
                case VOUCHERS:
                case USER_BOOKINGS:
                case ALL_BOOKINGS:
                case MY_BOOKINGS:
                case PASSWORD:
                case CRONS:
                case CRON:
                case PROFILE_PRESIGNED_URL:
                case PROFILE_IMAGE_LOC:
                case TRIAL_USERS:
                case UNMARKED_EVENT:
                case USER_MEAL_TEMPLATE:
                case USER_EXPERT_REPORT:
                case USER_OFFERING:
                case LAB_TESTS:
                case TASK_THUMBNAIL_PRESIGNED_URL:
                case TASK:
                case TASKS:
                case CHALLENGE_THUMBNAIL_PRESIGNED_URL:
                case CHALLENGE:
                case CHALLENGES:
                case WEBINARS:
                case WEBINAR:
                case WEBINAR_USERS:
                case LEADERBOARD:
                case CHALLENGE_USER:
                case USER_BOOKINGS_PERIOD_LOGS:
                case USER_BOOKINGS_WEBINAR_LOGS:
                case USER_BOOKINGS_SYMPTOM_LOGS:
                case USER_BOOKINGS_CONSULT_LOGS:
                case MEALS:
                case TOPICS:
                case CONTENT_ITEMS:
                case FEATURE_CREATE_TOPICS:
                case FEATURE_UPDATE_TOPICS:
                case FEATURE_DELTE_TOPICS:
                case FEATURE_CREATE_CONTENT:
                case FEATURE_DELTE_CONTENT:
                case FEATURE_UPDATE_CONTENT:
                case TOPIC_CONTENT_MAP:
                case TOPIC_CONTENT_ADD:
                case TOPIC_CONTENT_REMOVE:
                case HEALTH_COACH:
                case PAYMENT:
                case PAYMENT_INSIGHTS:
                case EVENTS_CALENDAR:
                case FILTERED_CALENDAR_EVENTS:
                case PAYMENTS_PLAN:
                case PAYMENTS_PLAN_DETAILS:
                case LAB_REPORT_PRESIGNED_URL:   
                case LAB_REPORT_URL:             
                case CHALLENGE_HERO_PRESIGNED_URL:
                case CHALLENGE_LANDING_PRESIGNED_URL:  
                case USER_LAB_REPORT:
                case MEAL_TEMPLATE_PRESIGNED_URL:
                case MEAL_TEMPLATE:
                case EXPERTS:
                case EXPERT:       
                case SPEC:      
                case CHALLENGE_ENGAGEMENT:
                case LAB_TESTS_CONFIG:
                case LAB_TEST_THUMBNAIL_PRESIGNED_URL:
                case LAB_TEST_SKU:
                case LAB_TEST_SKUS:
                case SKUS:
                case SKU:
                case EXPERT_STATUS:
                case CONTENT_PRESIGNED_URL:
                case COMMENT:
                case ORDERS:
                case ORDER:
                case DOCTORS:
                case DOCTOR:
                case DOCTOR_VERIFIED:
                case DOCTOR_ONBOARDING:
                case DOCTOR_PROFILE_PRESIGNED_URL:
                case SKU:
                case COUPON:
                case VOUCHER:
                case PATIENT_TO_DOCTOR:
                case DOCTOR_PATIENTS:
                case ADMIN_LOGIN:
                    if(action.meta.method==='get'){
                        resp = await axios.get(action.meta.url,{withCredentials:true,credentials:'include',
                            params:{queryVal:action.payload}}
                        )
                        dispatch(apiSuccess(resp.data.responseData,action.meta.feature,resp.data.responseStatus.message))                        
                        break;
                    }
                    if(action.meta.method==='post'){
                        resp = await axios.post(action.meta.url,action.payload,{withCredentials:true,credentials:'include'})    
                    }else if(action.meta.method==='put'){
                        resp = await axios.put(action.meta.url,action.payload,{withCredentials:true,credentials:'include'})
                    }else if(action.meta.method==='delete'){
                        resp = await axios.delete(action.meta.url,{withCredentials:true,credentials:'include',data:{...action.payload}})
                    }
                    dispatch(apiSuccess(resp.data.responseData,action.meta.feature,resp.data.responseStatus.message))                        

                    break;
                default:break; 
        }
    }catch(err){
        if(err.response){
            if(err.response.status===401 && action.meta.feature !== LOGIN){
                dispatch(forceLogout())  
                return;
            }else if(action.meta.feature === LOGIN){
                dispatch(setAuthentication({isAuthenticated:false}))
            }   
        }
        dispatch(apiError(err,action.meta.feature))
    }
}}