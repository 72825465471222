import React from "react";
import Select from "react-select";

const CustomSelect = ({ value, options, placeholder, onChange, isMulti = false }) => {
  return (
    <div>
      <Select
        value={value}
        options={options}
        placeholder={placeholder}
        onChange={onChange}
        isMulti={isMulti}
        noOptionsMessage={() => "Not Found"}
      />
    </div>
  );
};

export default CustomSelect;