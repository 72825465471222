import React, { useState, useEffect } from "react";
import Navbar from "../../navbar";
import BasicInfoForm from "./BasicInfoForm";
import OtherInfoForm from "./OtherInfoForm";
import MedicalInfo from "./MedicalInfoForm";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
    createNewUser,
    editUserDetails,
    getUserDetails,
} from "../../../actions/user";
import { getAllUsers } from "../../../actions/users";
import { userSelector } from "../../../selector/user";

const CreateNewUser = (props) => {
    const dispatch = useDispatch();

    const userId = props.match.params.userId;
    useEffect(() => {
        if (userId) {
            dispatch(getUserDetails(userId));
        }
    }, [dispatch, userId]);

    const userDetails = useSelector(
        (state) => userSelector(state),
        shallowEqual
    );

    const [basicInfoActive, setBasicInfoActive] = useState("active");
    const [otherInfoActive, setOtherInfoActive] = useState("");
    const [medicalInfoActive, setMedicalInfoActive] = useState("");

    //Form Parameters

    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [countryCode,setCountryCode] = useState("");
    const [callingNumber, setCallingNumber] = useState("");
    const [whatsappNumber,setWhatsappNumber] = useState("")
    const [age, setAge] = useState("");

    const [height, setHeight] = useState("");
    const [weight, setWeight] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [acqChannel, setAcqChannel] = useState('Whatsapp'); 

    const [patientHistory, setPatientHistory] = useState("");
    const [diagnosis, setDiagnosis] = useState('Not Diagnosed');
    const [ageOfDiagnosis, setAgeOfDiagnosis] = useState("");
    const [physicalFitnessLevel, setPhysicalFitnessLevel] = useState("");
    const [workoutType, setWorkoutType] = useState("");
    const [treatmentsTaken, setTreatmentsTaken] = useState("");
    const [currentTreatment, setCurrentTreatment] = useState("");
    const [programGoal, setProgramGoal] = useState("");
    const [reportUrl,setReportUrl] = useState("");
    const [healthCoach,setHealthCoach] = useState("");
    const [primaryConcern, setPrimaryConcern] = useState("");
    const [secondaryConcern, setSecondaryConcern] = useState("");
    const [symptom, setSymptom] = useState("");

    useEffect(() => {
        if (userId) {
            setEmail(userDetails ? userDetails.email : "");
            setFirstName(userDetails ? userDetails.firstName : "");
            setMiddleName(userDetails ? userDetails.middleName : "");
            setLastName(userDetails ? userDetails.lastName : "");
            setCountryCode(userDetails? userDetails.countryCode:"");
            setCallingNumber(userDetails ? userDetails.callingNumber : "");
            setWhatsappNumber(userDetails ? userDetails.whatsappNumber : "")
            setAge(userDetails ? userDetails.age :"");

            setHeight(userDetails ? userDetails.height : "");
            setWeight(userDetails ? userDetails.weight : "");
            setCity(userDetails ? userDetails.city : "");
            setState(userDetails ? userDetails.state : "");
            setAcqChannel(userDetails ? userDetails.acqChannel :"");

            setPatientHistory(userDetails ? userDetails.patientHistory : "");
            setDiagnosis(userDetails ? userDetails.diagnosis :"");
            setAgeOfDiagnosis(userDetails ? userDetails.ageOfDiagnosis :"")
            setPhysicalFitnessLevel(userDetails ? userDetails.phsysicalFitnessLevel : "");
            setWorkoutType(userDetails ? userDetails.workoutTypes : "");
            setTreatmentsTaken(userDetails ? userDetails.treatmentsTaken : "");
            setCurrentTreatment(userDetails ? userDetails.currentTreatment :"");
            setProgramGoal(userDetails ? userDetails.programGoal : "");
            setReportUrl(userDetails ? userDetails.reportUrl :"")
            setHealthCoach(userDetails ? userDetails.healthCoach :"")
            setPrimaryConcern(userDetails ? userDetails.primaryConcern : []);
            setSecondaryConcern(userDetails ? userDetails.secondaryConcern :"")
            setSymptom(userDetails ? userDetails.symptom : []);
        }
        else{
            setEmail("");
            setFirstName("");
            setMiddleName("");
            setLastName("");
            setCountryCode("");
            setCallingNumber("");
            setWhatsappNumber("")
            setAge("");
        }
    }, [userDetails, userId]);

    const basicItemClick = () => {
        setBasicInfoActive("active");
        setOtherInfoActive("");
        setMedicalInfoActive("");
    };
    const otherItemClick = () => {
        setBasicInfoActive("");
        setOtherInfoActive("active");
        setMedicalInfoActive("");
    };
    const medicalItemClick = () => {
        setBasicInfoActive("");
        setOtherInfoActive("");
        setMedicalInfoActive("active");
    };

    const history = useHistory();
    const handleSubmit = () => {
        
        if (userId) {
            dispatch(
                editUserDetails({
                    email,
                    firstName,
                    middleName,
                    lastName,
                    countryCode,
                    callingNumber,
                    whatsappNumber,
                    age,
                    height,
                    weight,
                    city,
                    state,
                    acqChannel,
                    patientHistory,
                    diagnosis,
                    ageOfDiagnosis,
                    physicalFitnessLevel,
                    workoutType,
                    treatmentsTaken,
                    currentTreatment,
                    programGoal,
                    reportUrl,
                    healthCoach,
                    primaryConcern,
                    secondaryConcern,
                    symptom,
                    userId,
                })
            );
        } else {
            dispatch(
                createNewUser({
                email,
                firstName,
                middleName,
                lastName,
                countryCode,
                callingNumber,
                whatsappNumber,
                age,
                height,
                weight,
                city,
                state,
                patientHistory,
                acqChannel,
                diagnosis,
                ageOfDiagnosis,
                physicalFitnessLevel,
                workoutType,
                treatmentsTaken,
                currentTreatment,
                programGoal,
                reportUrl,
                healthCoach,
                primaryConcern,
                secondaryConcern,
                symptom
            })
            )
        }
        dispatch(getAllUsers({}));
        history.push("/allUsers");
    }
    const renderPartForm = () => {
        if (basicInfoActive === "active") {
            return (
                <BasicInfoForm
                    setBasicInfoActive={setBasicInfoActive}
                    setOtherInfoActive={setOtherInfoActive}
                    setMedicalInfoActive={setMedicalInfoActive}

                    email={email}
                    setEmail={setEmail}
                    firstName={firstName}
                    setFirstName={setFirstName}
                    middleName={middleName}
                    setMiddleName={setMiddleName}
                    lastName={lastName}
                    setLastName={setLastName}
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                    callingNumber={callingNumber}
                    setCallingNumber={setCallingNumber}
                    whatsappNumber={whatsappNumber}
                    setWhatsappNumber={setWhatsappNumber}
                    age={age}
                    setAge={setAge}
                />
            );
        } else if (otherInfoActive === "active") {
            return (
                <OtherInfoForm
                    setBasicInfoActive={setBasicInfoActive}
                    setOtherInfoActive={setOtherInfoActive}
                    setMedicalInfoActive={setMedicalInfoActive}

                    height={height}
                    setHeight={setHeight}
                    weight={weight}
                    setWeight={setWeight}
                    city={city}
                    setCity={setCity}
                    state={state}
                    setState={setState}
                    acqChannel={acqChannel}
                    setAcqChannel={setAcqChannel}
                />
            );
        } else if (medicalInfoActive === "active") {
            return (
                <MedicalInfo
                    setBasicInfoActive={setBasicInfoActive}
                    setOtherInfoActive={setOtherInfoActive}
                    setMedicalInfoActive={setMedicalInfoActive}

                    patientHistory={patientHistory}
                    setPatientHistory={setPatientHistory}
                    diagnosis={diagnosis}
                    setDiagnosis={setDiagnosis}
                    ageOfDiagnosis={ageOfDiagnosis}
                    setAgeOfDiagnosis={setAgeOfDiagnosis}
                    phsysicalFitnessLevel={physicalFitnessLevel}
                    setPhysicalFitnessLevel={setPhysicalFitnessLevel}
                    workoutType={workoutType}
                    setWorkoutType={setWorkoutType}
                    treatmentsTaken={treatmentsTaken}
                    setTreatmentsTaken={setTreatmentsTaken}
                    currentTreatment={currentTreatment}
                    setCurrentTreatment={setCurrentTreatment}
                    programGoal={programGoal}
                    setProgramGoal={setProgramGoal}
                    reportUrl={reportUrl}
                    setReportUrl={setReportUrl}
                    healthCoach={healthCoach}
                    setHealthCoach={setHealthCoach}
                    primaryConcern={primaryConcern}
                    setPrimaryConcern={setPrimaryConcern}
                    secondaryConcern={secondaryConcern}
                    setSecondaryConcern={setSecondaryConcern}
                    symptom={symptom}
                    setSymptom={setSymptom}
                    handleSubmit={handleSubmit}
                    userId={userId}
                />
            );
        }
    };

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "/assets/js/advancedForm.js";
        script.async = true;
        document.body.appendChild(script);
        setBasicInfoActive("active");
        return () => {
            document.body.removeChild(script);
        };
    }, [dispatch]);

    return (
        <Navbar pageTitle={"Create New User"}>
            <ul className="nav nav-pills bg-nav-pills nav-justified mb-4">
                <li
                    className="nav-item"
                    onClick={() => {
                        basicItemClick();
                    }}
                >
                    <a
                        href="#home1"
                        data-bs-toggle="tab"
                        aria-expanded="false"
                        className={`nav-link rounded-0 ${basicInfoActive}`}
                    >
                        <i className="mdi mdi-home-variant d-md-none d-block"></i>
                        <span className="d-none d-md-block">Basic Info</span>
                    </a>
                </li>
                <li
                    className="nav-item"
                    onClick={() => {
                        otherItemClick();
                    }}
                >
                    <a
                        href="#profile1"
                        data-bs-toggle="tab"
                        aria-expanded="true"
                        className={`nav-link rounded-0 ${otherInfoActive}`}
                    >
                        <i className="mdi mdi-account-circle d-md-none d-block"></i>
                        <span className="d-none d-md-block">Other Info</span>
                    </a>
                </li>
                <li
                    className="nav-item"
                    onClick={() => {
                        medicalItemClick();
                    }}
                >
                    <a
                        href="#settings1"
                        data-bs-toggle="tab"
                        aria-expanded="false"
                        className={`nav-link rounded-0 ${medicalInfoActive}`}
                    >
                        <i className="mdi mdi-settings-outline d-md-none d-block"></i>
                        <span className="d-none d-md-block">Medical Info</span>
                    </a>
                </li>
            </ul>
            {renderPartForm()}
        </Navbar>
    );
};

export default CreateNewUser;
