import { apiRequest,API_SUCCESS,API_ERROR } from "../../../actions/api"
import {ROOT_URL} from '../../../config/'
import { setAllUsers, setTrialUsers, setHealthCoach, getTrialUsers, setAdminLogin, ADMIN_LOGIN, ADMIN_LOGIN_GET, TRIAL_USERS, TRIAL_USERS_GET, USERS,USERS_GET, HEALTH_COACH, HEALTH_COACH_GET, HEALTH_COACH_UPDATE } from "../../../actions/users"

export const usersMiddleware = ({dispatch}) => next => (action) =>{
    next(action)
    switch(action.type){
        case USERS_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/allUser`,
                method:'post',
                feature:USERS
            }))
            break;
        case ADMIN_LOGIN_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/adminLogin`,
                method:'get',
                feature:ADMIN_LOGIN
            }))
            break;
        case TRIAL_USERS_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/trialBookings`,
                method:'get',
                feature:TRIAL_USERS
            }))
            break;
        case HEALTH_COACH_GET:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/healthCoach`,
                method:'get',
                feature:HEALTH_COACH
            }))
            break;
        case HEALTH_COACH_UPDATE:
            dispatch(apiRequest({
                body:action.payload,
                config:{},
                url:`${ROOT_URL}/assignHealthCoach`,
                method:'put',
                feature:HEALTH_COACH
            }))
            break;

        case `${TRIAL_USERS} ${API_SUCCESS}`:
            dispatch(setTrialUsers(action.payload))
            break;
        case `${TRIAL_USERS} ${API_ERROR}`:
            break;
        case `${USERS} ${API_SUCCESS}`:
            dispatch(setAllUsers(action.payload))
            break;
        case `${ADMIN_LOGIN} ${API_SUCCESS}`:
            dispatch(setAdminLogin(action.payload))
            break;
        case `${USERS} ${API_ERROR}`:
            break;
        case `${HEALTH_COACH} ${API_SUCCESS}`:
            if(action.message === 'Health Coach assigned')
            {
                dispatch(getTrialUsers({start: action.payload.start, end: action.payload.end}))
            }
            else{
                dispatch(setHealthCoach(action.payload))
            }
            break;
        case `${HEALTH_COACH} ${API_ERROR}`:
            break;
        default: break;
    }
}