export const EVENT = `[EVENT]`
export const EVENTS = `[EVENTS]`
export const EVENTS_CALENDAR = `[EVENTS_CALENDAR]`
export const FILTERED_EVENTS = `[FILTERED_EVENTS]`
export const FILTERED_CALENDAR_EVENTS = `[FILTERED_CALENDAR_EVENTS]`
export const RESCHEDULE = `[RESCHEDULE]`

export const EVENT_FILTER_SET = `[EVENT_FILTER] SET`;
export const DATE_FILTER_SET = `[DATE_FILTER] SET`;



export const EVENT_CREATE = `${EVENT} CREATE`
export const EVENT_GET = `${EVENT} GET`
export const EVENT_SET = `${EVENT} SET`
export const EVENT_RESCHEDULE = `${EVENT} ${RESCHEDULE}`
export const EVENT_EDIT = `${EVENT} EDIT`
export const EVENT_DELETE = `${EVENT} DELETE`
export const EVENT_MARK = `${EVENT} MARK`
export const EVENT_UNMARK = `${EVENT} UNMARK`
export const EVENTS_CALENDAR_GET = `${EVENTS_CALENDAR} CALENDAR GET`
export const EVENTS_CALENDAR_SET = `${EVENTS_CALENDAR} CALENDAR SET`


export const EVENTS_GET = `${EVENTS} GET`
export const EVENTS_SET = `${EVENTS} SET`
export const FILTERED_EVENTS_SET = `${FILTERED_EVENTS} SET`
export const FILTERED_CALENDAR_EVENTS_GET = `${FILTERED_CALENDAR_EVENTS} GET`
export const FILTERED_CALENDAR_EVENTS_SET = `${FILTERED_CALENDAR_EVENTS} SET`;

export const createEvent = (data) => ({
    type: EVENT_CREATE,
    payload: data
})

export const getAllEvents = (data) => ({
    type: EVENTS_GET,
    payload:data
})

export const setAllEvents = (data) => ({
    type: EVENTS_SET,
    payload:data
})

export const getAllCalendarEvents = (data) => ({
    type: EVENTS_CALENDAR_GET,
    payload:data
})

export const setAllCalendarEvents = (data) => ({
    type: EVENTS_CALENDAR_SET,
    payload:data
})

export const setFilteredEvents = (data) =>({
    type: FILTERED_EVENTS_SET,
    payload:data
})

export const getFilteredCalendarEvents = (data) => ({
    type: FILTERED_CALENDAR_EVENTS_GET,
    payload:data
})

export const setFilteredCalendarEvents = (data) =>({
    type: FILTERED_CALENDAR_EVENTS_SET,
    payload:data
})

export const rescheduleEvent = (data) =>({
    type:EVENT_RESCHEDULE,
    payload:data
})

export const deleteEvent = (data) => ({
    type:EVENT_DELETE,
    payload:data
})

export const getEventDetails = (data) =>({
    type: EVENT_GET,
    payload:data
})

export const setEventDetails = (data) => ({
    type: EVENT_SET,
    payload:data
})

export const editEvent = (data) =>({
    type:EVENT_EDIT,
    payload:data
})

export const markEvent = (data) => ({
    type: EVENT_MARK,
    payload:data
})

export const unmarkEvent = (data) => ({
    type: EVENT_UNMARK,
    payload:data
})

export const setEventFilter = (data) => ({
    type: EVENT_FILTER_SET,
    payload: data
})

export const setDateFilter = (data) => ({
    type: DATE_FILTER_SET,
    payload: data
})