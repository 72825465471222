// Placeholder texts
export const SEARCH_CONTACT_PLACEHOLDER = "Enter contact number";
export const PLAN_NAME_PLACEHOLDER = "Select Plan";
export const ACQ_CHANNEL_PLACEHOLDER = "Select Acquisition Channel";

// Filter options
export const FILTER_BY_OPTIONS = [
  { value: "", label: "Filter Users By", disabled: true },
  { value: "Plan Name", label: "Plan Name" },
  { value: "Acquisition Channel", label: "Acquisition Channel" },
];

// Acquisition channel options
export const ACQ_CHANNEL_LIST = [
  { label: "App", value: "App" },
  { label: "Landing Checkout", value: "Landing Checkout" },
  { label: "Instagram", value: "Instagram" },
  { label: "WhatsApp", value: "WhatsApp" },
];

export const PAGE_SIZE = 20 