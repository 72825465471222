/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Navbar from "../navbar";
import axios from "axios";
import Alert from "sweetalert2";
import { DateRangePicker } from "react-dates";

import { getAllLabTests, updateLabReportUrl } from "../../actions/labTests";
import { labTestsSelector } from "../../selector/labTests";
import { useHistory } from "react-router-dom";

import { getLabReportPresignedUrl } from "../../actions/labTests";
import { labReportPresignedUrlSelector } from "../../selector/presignedUrlSelector";
import { CircleProgress } from "react-gradient-progress";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-material.css";
import "./icon.css";

const CreateStatusBadge = (props) => {
  const status = props.data.status;
  if (status === "Pending") {
    return <span className={`badge bg-warning`}>{status}</span>;
  } else if (status === "Failure") {
    return <span className={`badge bg-danger`}>{status}</span>;
  } else {
    return <span className={`badge bg-success`}>{status}</span>;
  }
};

const UserDetailsRoute = (props) => {
  const history = useHistory();
  const { userId, name } = props.data.userName;

  if (userId !== null) {
    return (
      <span
        style={{ color: "blue" }}
        onClick={() => {
          history.push(`/userDetails/${userId}`);
        }}
      >
        {name}
      </span>
    );
  } else {
    return <>{name}</>;
  }
};

const UploadReportFile = (props) => {
  const item = props.data.upload;

  const dispatch = useDispatch();

  const [labReport, setLabReport] = useState("");
  const [progress, setProgress] = useState(0);
  const [disable, setDisable] = useState(true);

  const onFileChange = (e, orderId) => {
    e.preventDefault();
    setLabReport({ orderId, file: e.target.files[0] });
    dispatch(getLabReportPresignedUrl(e.target.files[0].type));
  };

  const uploadConfigs = useSelector((state) =>
    labReportPresignedUrlSelector(state)
  );

  const uploadFile = async (e, orderId) => {
    setDisable(false);
    const config = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        setProgress(percent);
      },
      headers: {
        "Content-Type": labReport.file.type,
      },
    };

    await axios
      .put(uploadConfigs.url, labReport.file, config)
      .then((val) => {
        dispatch(
          updateLabReportUrl({
            labReportUrl: `https://lab-test-users.s3.amazonaws.com/${uploadConfigs.key}`,
            orderId,
            start: item.start,
            end: item.end,
          })
        );

        setLabReport("");
        setProgress(0);
        setDisable(true);
      })
      .catch((err) => {
        setLabReport("");
        setDisable(true);
        setProgress(0);

        Alert.fire({
          position: "top-end",
          icon: "error",
          title: "Lab Report Failed",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };
  return (
    <div>
      {item.labReportUrl === "" || item.labReportUrl === null ? (
        <>
          {!disable && labReport.orderId === item.orderId ? (
            <CircleProgress percentage={progress} width={80} />
          ) : (
            <>
              {labReport.orderId !== item.orderId ? (
                <div className="file-upload" style={{ marginLeft: "50px" }}>
                  <label for="file-input">
                    <i className="dripicons-cloud-upload text-info"></i>
                  </label>
                  <input
                    id="file-input"
                    type="file"
                    accept="application/pdf"
                    onChange={(event) => {
                      onFileChange(event, item.orderId);
                    }}
                  />
                </div>
              ) : (
                <div className="col">
                  <h5>{labReport ? labReport.file.name : ""}</h5>
                  <button
                    className="btn btn-info btn-rounded"
                    onClick={(e) => uploadFile(e, item.orderId)}
                    style={{ padding: "2px", width: "100px" }}
                  >
                    Upload
                  </button>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <a href={item.labReportUrl}>{`${item.name} Lab Report`}</a>
      )}
    </div>
  );
};

const Table = ({ labTestsData, start, end }) => {
  const data = labTestsData
    ? labTestsData.map((item) => ({
        orderId: item.orderId,
        userName: { userId: item.userId, name: item.name },
        callingNumber: item.callingNumber,
        email: item.email,
        dob: item.dob,
        address: item.address,
        pincode: item.pincode,
        status: item.status,
        labTestName: item.skuName,
        upload: {
          labReportUrl: item.reportUrl,
          orderId: item.orderId,
          start,
          end,
          name: item.name,
        },
        labPartner: item.partner_name,
        labTestTimings: moment(item.itemId).format("Do MMMM YYYY, h:mm: A"),
        paymentTimestamp: moment(item.paymentTimestamp).format(
          "Do MMMM YYYY, h:mm: A"
        ),
        amount: `₹${Math.round(item.price, 2)}`,
        couponCode: item.promoCode,
      }))
    : {};

  const [columnDefs] = useState([
    {
      headerName: "OrderId",
      field: "orderId",
      resizable: true,
      sortable: true,
      filter: true,
      width: 220,
    },
    {
      headerName: "Name",
      field: "userName",
      cellRenderer: UserDetailsRoute,
      resizable: true,
      sortable: true,
      filter: true,
      width: 200,
    },
    {
      headerName: "Contact",
      field: "callingNumber",
      resizable: true,
      sortable: true,
      filter: true,
      width: 200,
    },
    {
      headerName: "Email",
      field: "email",
      resizable: true,
      sortable: true,
      filter: true,
      width: 280,
      tooltipField: "email",
    },
    {
      headerName: "DOB",
      field: "dob",
      resizable: true,
      sortable: true,
      filter: true,
      width: 200,
    },
    {
      headerName: "Address",
      field: "address",
      resizable: true,
      sortable: true,
      filter: true,
      wrapText: true,
      autoHeight: true,
      width: 300,
    },
    {
      headerName: "Pincode",
      field: "pincode",
      resizable: true,
      sortable: true,
      filter: true,
      width: 100,
    },
    {
      headerName: "Status",
      field: "status",
      cellRenderer: CreateStatusBadge,
      resizable: true,
      sortable: true,
      filter: true,
      width: 100,
    },
    {
      headerName: "Lab Test Name",
      field: "labTestName",
      resizable: true,
      sortable: true,
      filter: true,
      wrapText: true,
      autoHeight: true,
      width: 200,
    },
    {
      headerName: "Upload Lab Report",
      field: "upload",
      cellRenderer: UploadReportFile,
      resizable: true,
      autoHeight: true,
      width: 200,
    },
    {
      headerName: "Lab Partner",
      field: "labPartner",
      resizable: true,
      sortable: true,
      filter: true,
      width: 150,
    },
    {
      headerName: "Lab Test Timings",
      field: "labTestTimings",
      resizable: true,
      sortable: true,
      filter: true,
      width: 250,
    },
    {
      headerName: "Payment Timestamp",
      field: "paymentTimestamp",
      resizable: true,
      sortable: true,
      filter: true,
      width: 250,
    },
    {
      headerName: "Amount",
      field: "amount",
      resizable: true,
      sortable: true,
      filter: true,
      width: 100,
    },
    {
      headerName: "Coupon Code",
      field: "couponCode",
      resizable: true,
      sortable: true,
      filter: true,
      width: 200,
    },
  ]);

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current.api.setQuickFilter(
      document.getElementById("filter-text-box").value
    );
  }, []);

  const getRowStyle = (params) => {
    return {
      fontFamily: "Cerebri Sans",
      fontSize: "0.9rem",
      border: "none",
      borderBottom: "1px solid #eef2f7",
    };
  };

  const gridRef = useRef();

  const popupParent = useMemo(() => {
    return document.body;
  }, []);

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-body">
          <div className="tab-content">
            <div className="tab-pane show active" id="basic-datatable-preview">
              <div
                id="basic-datatable-wrapper"
                className="ag-theme-material"
                style={{ height: "100vh" }}
              >
                <div className="row d-flex justify-content-between px-3">
                  <input
                    type="text"
                    id="filter-text-box"
                    placeholder="Type a keyword..."
                    aria-label="Type a keyword..."
                    className="gridjs-input gridjs-search-input mb-2"
                    onInput={onFilterTextBoxChanged}
                  />
                </div>
                <AgGridReact
                  ref={gridRef}
                  rowData={data}
                  columnDefs={columnDefs}
                  pagination={true}
                  paginationPageSize={20}
                  cacheQuickFilter={true}
                  popupParent={popupParent}
                  enableCellTextSelection={true}
                  suppressCellSelection={true}
                  getRowStyle={getRowStyle}
                  rowHeight={20}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const LabTests = () => {
  const dispatch = useDispatch();

  const [start, setStart] = useState(moment().subtract(7, "days"));
  const [end, setEnd] = useState(moment());
  const [focusedInput, setFocusedInput] = useState();

  useEffect(() => {
    dispatch(getAllLabTests({ start, end }));
  }, [dispatch, start, end]);

  const labTestsData = useSelector((state) => labTestsSelector(state));
  return (
    <div>
      <Navbar>
        <div className="row">
          <div className="row mb-3">
            <div className="col-8">
              <h3 className="page-title page-title-text">
                Lab Tests Transactions
              </h3>
            </div>
            <div className="col-4 d-flex justify-content-end">
              <DateRangePicker
                startDate={start}
                startDateId="start-date"
                endDate={end}
                endDateId="end-date"
                onDatesChange={({ startDate, endDate }) => {
                  setStart(startDate);
                  setEnd(endDate);
                }}
                focusedInput={focusedInput}
                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                displayFormat="DD/MM/YYYY"
                isOutsideRange={() => false}
                minimumNights={0}
                pagination={true}
                paginationPageSize={20}
              />
            </div>
          </div>
        </div>
        <Table labTestsData={labTestsData} start={start} end={end} />
      </Navbar>
    </div>
  );
};

export default LabTests;
