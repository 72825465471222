import React, {useState,useEffect} from 'react'
import { useDispatch,useSelector } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from '@fullcalendar/interaction';
import BootstrapTheme from '@fullcalendar/bootstrap';
import Alert from "sweetalert2";
import moment from 'moment';
import { useHistory } from 'react-router';

import Navbar from '../../navbar';
import { deleteEvent, getAllEvents, rescheduleEvent, setEventFilter, getFilteredCalendarEvents } from '../../../actions/event';
import { eventFilterSelector, filteredCalendarEventsSelector } from '../../../selector/Event';
import { offeringTypeSelector } from '../../../selector/offering';
import { getOfferingType } from '../../../actions/offering';
import { getProvider } from '../../../actions/provider';
import { providerSelector } from '../../../selector/provider';



const Calendar = () =>{
    const dispatch = useDispatch()
    const history = useHistory()

    const filter = useSelector((state) => eventFilterSelector(state));

    const filteredCalendarEvents = useSelector((state=>filteredCalendarEventsSelector(state)))

    const [filterBy,setFilterBy] = useState(filter?filter.filterBy:'')
    const [ledBy,setLedBy] = useState(filter?filter.filterValue:'')
    const [eventType,setEventType] = useState(filter?filter.filterValue:'')
    const offeringType = useSelector(state=>offeringTypeSelector(state))
    const provider = useSelector(state=>providerSelector(state))

    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')

    useEffect(()=>{
        dispatch(getOfferingType())
        dispatch(getProvider())
    },[dispatch])

    useEffect(()=>{
        if (startDate && endDate) {
            dispatch(getFilteredCalendarEvents({
                startDate,
                endDate,
                ledBy,
                eventType
            }))
        }
    },[dispatch, startDate, endDate, ledBy, eventType])

    const eventClick = eventClick => {
        const {eventName, eventType,joinUrl,startUrl,ledBy,eventDuration} = eventClick.event._def.extendedProps
        const eventStart = moment(eventClick.event.start).format('Do, MMM YYYY HH:mm a')
        const handleDeleteEvent = (joinUrl) => {
            const meetingId = joinUrl.split('/').slice(-1)[0];
            dispatch(deleteEvent({meetingId,eventId:eventClick.event.id}))
        }
        Alert.fire({
            title:eventName,
            html:`
            <div class="row">
                <div class="col-12">
                    <div class="row">
                        <p>${eventType}</p>
                        <p>${ledBy}</p>
                    </div>
                    
                    <hr/>
                    <div class="row">  
                        <p> ${eventStart} (${eventDuration} minutes)</p>
                        <p>Meeting Link: <a href="${joinUrl}">${joinUrl}</a></p>
                        <div class="justify-content-center d-flex mb-2">
                            <a href="${startUrl}" target="_blank">
                                <button 
                                    class="btn text-white"
                                    style="background-color:#0E72ED"
                                >                   
                                    Start This Event
                                </button>
                            </a>
                        </div>
                    </div>
                    <hr/>
                </div>
            </div>
            `,
            showDenyButton: true,
            showConfirmButton:true,
            confirmButtonText:'Cancel This Event',
            denyButtonText:'Go to Event Details',
            denyButtonColor:"#3085d6",
            confirmButtonColor:'#d33',
        }).then(result =>{
            if(!result.isDismissed){
                if(result.value){
                    Alert.fire({
                        title:'Are you sure you want to delete this event ?',
                        showDenyButton:true,
                        showConfirmButton:true,
                        confirmButtonText:'Go Ahead !',
                        denyButtonText:'Nope Wait',
                    }).then(({value})=>{
                        if(value){
                            handleDeleteEvent(joinUrl)
                            eventClick.event.remove();
                        }
                    })
                }else{
                    history.push(`/eventDetails/${eventClick.event.id}`)
                }
            }
        })
    } 

    const handleReschedule = (data) =>{
        Alert.fire({
            title:'Are you sure you want to reschedule this event ?',
            showDenyButton:true,
            showConfirmButton:true,
            confirmButtonText:'Go Ahead !',
            denyButtonText:'Nope Wait',
        }).then(({value})=>{
            if(value){
                dispatch(rescheduleEvent(data))
            }else{
                dispatch(getAllEvents())
            }
        })
        
    }

    const handleLeadSelect = (value) =>{
        setLedBy(value)
        dispatch(setEventFilter({filterBy: filterBy, filterValue: value}))
    }
    
    const handleEventTypeSelect = (value) => {
        setEventType(value)
        dispatch(setEventFilter({filterBy: filterBy, filterValue: value}))
    }
    
    const eventTypeSelector = (eventType,offeringType) =>{
        return(
            <select
            className="form-select"
            id="led-by"
            value={eventType}
            onChange={e=>handleEventTypeSelect(e.target.value)}
            placeholder="Select Event Type"
        >
            <option value="" disabled selected>Select Event Type</option>
            {offeringType?offeringType.map((offer)=>{
                return(
                    <option 
                        key={offer.offeringTypeName}
                        value={offer.offeringTypeId}
                    >
                        {offer.offeringTypeName}
                    </option>
                )
            }):null}
        </select>
        )
    }

    const leadBySelector = (ledBy,provider) =>{
        return(
            <select
            className="form-select"
            id="led-by"
            value={ledBy}
            onChange={e=>handleLeadSelect(e.target.value)}
        >
            <option value="" disabled selected>Select a Coach</option>
            {provider?provider.map((item)=>(
                <option 
                    key={item.userId}
                    value={item.userId}
                >   {item.name} - {item.roleName} - {item.userId}
                </option>
                )):null}
        </select>
        )
    }

    const renderFilterValue = () =>{
        if(filterBy==='Coach Name'){
            return(
                leadBySelector(ledBy,provider)
            )
        }else if(filterBy==='Event Type'){
            return(
                eventTypeSelector(eventType,offeringType)
            )
        }else{
            return null
        }
    }
    const renderFullCalendar = (events) =>{
        return(
            <FullCalendar
                id="calendar"
                plugins={[BootstrapTheme,timeGridPlugin, interactionPlugin, dayGridPlugin]}
                initialView="dayGridMonth"

                dayMaxEvents={10}
                
                headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay"
                }}
                handleWindowResize={true}
                editable={true}
                selectable={true}
                allDaySlot={false}
                events={events}
                datesSet={(dateInfo) => {
                    setStartDate(moment(dateInfo.start).format('YYYY-MM-DD HH:mm:ss'));
                    setEndDate(moment(dateInfo.end).format('YYYY-MM-DD') + ' 23:59:59');
                }}
                dayHeaderContent = {(args) => {return moment(args.date).format('dddd')}}
                
                eventDrop={(info) => {
                    handleReschedule({
                        eventId:info.event.id,
                        start: moment(info.event.start).format('YYYY-MM-DD HH:mm'),
                        end: moment(info.event.end).format('YYYY-MM-DD HH:mm'),
                        title:info.event.title
                    })

                }}
                eventResize={(info) =>{
                    handleReschedule({
                        eventId:info.event.id,
                        start: moment(info.event.start).format('YYYY-MM-DD HH:mm'),
                        end: moment(info.event.end).format('YYYY-MM-DD HH:mm'),
                        title:info.event.title
                    })
                }}
                eventClick={eventClick}
                dayHeaderFormat={(param) => {
                    return param.date.day.toString();
                }}
                
            />
        )
    }
    return (
        <Navbar pageTitle={"Events"}>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="text-start">
                                <div className="row mb-1">
                                    <div className="col-10">
                                        <p className="header-title mt-0 mb-3">
                                            Apply Filter
                                        </p>
                                    </div>
                                    <div className="col-2">
                                        <button 
                                        className="btn btn-danger w-100"
                                        onClick={()=>{
                                            setFilterBy('')
                                            setEventType('')
                                            setLedBy('')
                                            dispatch(setEventFilter({}))
                                        }}
                                        >
                                            Clear All
                                        </button> 
                                    </div>                                
                                </div>

                                <div className="row">
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-3">
                                                <label
                                                    htmlFor="filterBy"
                                                    className="col-form-label"
                                                >
                                                    Filter By:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                <select
                                                    className="form-select"
                                                    id="led-by"
                                                    value={filterBy}
                                                    onChange={e=>setFilterBy(e.target.value)}
                                                >
                                                    <option value="" disabled selected>Filter Events By</option>
                                                    <option>Coach Name</option>
                                                    <option>Event Type</option>
                                                </select>
                                            </div>                                            
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-3">
                                                <label
                                                    htmlFor="filterBy"
                                                    className="col-form-label"
                                                >
                                                    Filter Value:
                                                </label>
                                            </div>
                                            <div className="col-9">
                                                {renderFilterValue()}
                                            </div>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">

                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="mt-4 mt-lg-0">
                                        {renderFullCalendar(filteredCalendarEvents)}
                                    </div>
                                </div> 

                            </div> 
                        </div> 
                    </div>
                </div>
            </div>
        </Navbar>
    )
}

export default Calendar;


